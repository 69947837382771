import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OurRoutes from './Routes';
import * as Routers from './Routers';
import Loader from '../components/container/Loader/Loader'
import configureStore from '../component1/container/Admin/store/configureStore';
import initialState from '../component1/container/Admin/store/InitialState.config';
import { Provider } from 'react-redux';
function AppRouter() {
  return (
    <Provider store={configureStore({ initialState })}>
    <Router>
      <Suspense fallback={<div><Loader/></div>} >
        <Routes>
          

          {/* super admin pages */}
          <Route path={OurRoutes.MAIL_URL} element={<Routers.MAIL_URL />} />

          <Route path={OurRoutes.SUPER_ADMIN_LOGIN} element={<Routers.SuperAdminLoginPage />} />
          <Route element={<Routers.SuperAdminDashboardLayoutPage />}>
          {/* <Route path={OurRoutes.CARE_PAY_LOGIN} element={<Routers.CARE_PAY_LOGIN />} /> */}
          <Route path={OurRoutes.LEAD_MANAGEMENT} element={<Routers.LEAD_MANAGEMENT />} />
          <Route path={OurRoutes.ALL_LEADS} element={<Routers.ALL_LEADS />} />
          <Route path={OurRoutes.APPLICATION_STATUS} element={<Routers.APPLICATION_STATUS />} />
          <Route path={OurRoutes.INCOMPLETE_APPLICATION_COMMUNICATION} element={<Routers.INCOMPLETE_APPLICATION_COMMUNICATION />} />
          <Route path={OurRoutes.COMMUNICATE_BY_STATUS} element={<Routers.COMMUNICATE_BY_STATUS />} />
          <Route path={OurRoutes.BULK_COMMUNICATION_LOGS} element={<Routers.BULK_COMMUNICATION_LOGS />} />
          <Route path={OurRoutes.COMMUNICATE_BY_CSV} element={<Routers.COMMUNICATE_BY_CSV />} />
          <Route path={OurRoutes.COMMUNICATE_BY_MOBILE} element={<Routers.COMMUNICATE_BY_MOBILE />} />
          <Route path={OurRoutes.AUTOMATION_LOGS} element={<Routers.AUTOMATION_LOGS />} />
          <Route path={OurRoutes.APPLICATION_ASSIGNMENT} element={<Routers.APPLICATION_ASSIGNMENT />} />
          <Route path={OurRoutes.UPDATE_ACCOUNT_INFO} element={<Routers.UPDATE_ACCOUNT_INFO />} />
          <Route path={OurRoutes.UPDATE_CIBIL_INFO} element={<Routers.UPDATE_CIBIL_INFO />} />
          <Route path={OurRoutes.ADD_PRIOR_MERCHANT} element={<Routers.ADD_PRIOR_MERCHANT />} />
          <Route path={OurRoutes.INCOMMING_REPORTS} element={<Routers.INCOMMING_REPORTS />} />
          <Route path={OurRoutes.GENERATE_REPORT_LOGS} element={<Routers.GENERATE_REPORT_LOGS />} />
          <Route path={OurRoutes.RECOVERY_LIST} element={<Routers.RECOVERY_LIST />} />
          <Route path={OurRoutes.DISBURSE_LIST} element={<Routers.DISBURSE_LIST />} />
          <Route path={OurRoutes.ASSETS_QUALITY} element={<Routers.ASSETS_QUALITY />} />
          <Route path={OurRoutes.NOTICE_DELIVERED} element={<Routers.NOTICE_DELIVERED />} />
          <Route path={OurRoutes.NACH_REGISTRATION} element={<Routers.NACH_REGISTRATION />} />
          <Route path={OurRoutes.INCOMING_LIST} element={<Routers.INCOMING_LIST />} />
          <Route path={OurRoutes.ADD_PRACTICE_DETAILS} element={<Routers.ADD_PRACTICE_DETAILS />} />
          <Route path={OurRoutes.ADD_PATIENT_LEAD_BY_DOCTOR} element={<Routers.ADD_PATIENT_LEAD_BY_DOCTOR />} />
          <Route path={OurRoutes.LENDING_SYSTEM_DASHBOARD} element={<Routers.LENDING_SYSTEM_DASHBOARD />} />
          <Route path={OurRoutes.SUPER_ADMIN_MERCHANT_PAYOUT} element={<Routers.SUPER_ADMIN_MERCHANT_PAYOUT />} />
          <Route path={OurRoutes.SUPER_ADMIN_MERCHANTY_PAYOUT_CONFIG} element={<Routers.SUPER_ADMIN_MERCHANTY_PAYOUT_CONFIG />} />
          <Route path={OurRoutes.SUPER_ADMIN_LENDER_DASHBOARD} element={<Routers.SUPER_ADMIN_LENDER_DASHBOARD />} />

            <Route path={OurRoutes.ADMIN_BULK_COMMUNICATION} element={<Routers.BulkCommunicationPage />} />

            <Route path={OurRoutes.SUPER_ADMIN_DASHBOARD} element={<Routers.SuperAdminDashboardPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_PRODUCT} element={<Routers.SuperAdminProductPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_PRODUCT} element={<Routers.SuperAdminAddProductPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_MODULE} element={<Routers.SuperAdminModulePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_MODULE} element={<Routers.SuperAdminAddModulePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ROLES} element={<Routers.SuperAdminRolesPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_ROLES} element={<Routers.SuperAdminAddRolesPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_LICENCE} element={<Routers.SuperAdminLicencePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LICENCE} element={<Routers.SuperAdminAddLicencePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_USER_MANAGEMENT} element={<Routers.SuperAdminUserManagementPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_USER} element={<Routers.SuperAdminAddUserPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_GROUP_MANAGEMENT} element={<Routers.SuperAdminGroupManagementPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_WORKFLOW} element={<Routers.SuperAdminWorkflowPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_WORKFLOW} element={<Routers.SuperAdminAddWorkflowPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_MAIL_CONFIG} element={<Routers.SuperAdminMailConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_MAIL_CONFIG} element={<Routers.SuperAdminAddMailConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_WHATSAPP_CONFIG} element={<Routers.SuperAdminWhatsappConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_WHATSAPP_CONFIG} element={<Routers.SuperAdminAddWhatsappConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_TEXT_MSG_CONFIG} element={<Routers.SuperAdminTextMsgConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_TEXT_MSG_CONFIG} element={<Routers.SuperAdminAddTextMsgConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_DOCUMENT_CONFIG} element={<Routers.SuperAdminDocumentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_DOCUMENT_CONFIG} element={<Routers.SuperAdminAddDocumentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_CREDIT_ASSESSMENT_CONFIG} element={<Routers.SuperAdminCreditAssessmentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_CREDIT_ASSESSMENT_CONFIG} element={<Routers.SuperAdminAddCreditAssessmentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_LENDER_PAGE} element={<Routers.SuperAdminLenderPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_LENDER_DETAIL_PAGE} element={<Routers.SuperAdminLendersDetailPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_PERSONAL} element={<Routers.SuperAdminAddLenderPersonalPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_ADDRESS} element={<Routers.SuperAdminAddLenderAddressPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_BANK} element={<Routers.SuperAdminAddLenderBankPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_DOCUMENT} element={<Routers.SuperAdminAddLenderDocumentPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_MAIL_TEMPLATE_CONFIG} element={<Routers.SuperAdminMailTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_MAIL_TEMPLATE_CONFIG} element={<Routers.SuperAdminAddMailTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_WHATSAPP_TEMPLATE_CONFIG} element={<Routers.SuperAdminWhatsappTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_WHATSAPP_TEMPLATE_CONFIG} element={<Routers.SuperAdminAddWhatsappTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_TEXT_MSG_TEMPLATE_CONFIG} element={<Routers.SuperAdminTextMsgTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_TEXT_MSG_TEMPLATE_CONFIG} element={<Routers.SuperAdminAddTextMsgTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_COMMUNITY_PAGE} element={<Routers.SuperAdminCommunityPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_COMMUNITY_DETAIL_PAGE} element={<Routers.SuperAdminCommunityDetailPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_PERSONAL} element={<Routers.SuperAdminAddCommunityPersonalPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_PROFESSIONAL} element={<Routers.SuperAdminAddCommunityProfessionalPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_ADDRESS} element={<Routers.SuperAdminAddCommunityAddressPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_BANK} element={<Routers.SuperAdminAddCommunityBankPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_DOCUMENT} element={<Routers.SuperAdminAddCommunityDocumentPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_CONTACT_PAGE} element={<Routers.SuperAdminContactPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_BLOG_MERCHANT} element={<Routers.SUPER_ADMIN_ADD_BLOG_MERCHANT />} />
            <Route path={OurRoutes.SUPER_ADMIN_BLOG_CONFIG_MERCHANT} element={<Routers.SUPER_ADMIN_BLOG_CONFIG_MERCHANT />} />
            <Route path={OurRoutes.SUPER_ADMIN_BLOG_DETAILS_MERCHANT} element={<Routers.SUPER_ADMIN_BLOG_DETAILS_MERCHANT />} />
            <Route path={OurRoutes.SUPER_ADMIN_BULKCOMMUNICATION_BY_EMAIL} element={<Routers.SUPER_ADMIN_BULKCOMMUNICATION_BY_EMAIL />} />
            <Route path={OurRoutes.ADMIN_QUERY_MERCHANT} element={<Routers.ADMIN_QUERY_MERCHANT />} />
            <Route path={OurRoutes.ALL_NBFC} element={<Routers.ALL_NBFC />} />
            <Route path={OurRoutes.ADD_NBFC} element={<Routers.ADD_NBFC />} />
            <Route path={OurRoutes.NBFC_CONFIGURATION} element={<Routers.NBFC_CONFIGURATION />} />
            <Route path={OurRoutes.BRE_CONFIG} element={<Routers.BRE_CONFIG />} />


            <Route path={OurRoutes.SUPER_ADMIN_ADD_BLOG} element={<Routers.SuperAdminAddBlog />} />
            <Route path={OurRoutes.SUPER_ADMIN_BLOG_CONFIG} element={<Routers.SuperAdminBlogConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_BLOG_DETAILS} element={<Routers.SuperAdminBlogDetails />} />


            <Route path={OurRoutes.SUPER_ADMIN_ESIGN_CONFIG} element={<Routers.SuperAdminEsignConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_ESIGN_CONFIG} element={<Routers.SuperAdminAddEsignConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ENASH_CONFIG} element={<Routers.SuperAdminEnashConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_ENASH_CONFIG} element={<Routers.SuperAdminAddEnashConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_STAGE} element={<Routers.SuperAdminStagePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_STAGE} element={<Routers.SuperAdminAddStagePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_DOC_TEMPLATE} element={<Routers.SuperAdminDocTemplatePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_DOC_TEMPLATE} element={<Routers.SuperAdminAddDocTemplatePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_DOWNLOAD_SHEET} element={<Routers.SuperAdminDownloadSheetPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ALL_LEADS} element={<Routers.SuperAdminAllLeadsPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_LEAD_DETAIL} element={<Routers.SuperAdminLeadDetailPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_REWARDS_CONFIG} element={<Routers.SuperAdminRewardsConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_REWARDS_CONFIG_TABLE} element={<Routers.SuperAdminRewardsConfigTable />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_REWARDS_CONFIG} element={<Routers.SuperAdminAddRewardsConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_NBFC} element={<Routers.SuperAdminNbfcConfig />} />
            {/* <Route path={OurRoutes.SUPER_ADMIN_ADD_NBFC} element={<Routers.SuperAdminAddNbfcConfig />} /> */}
            <Route path={OurRoutes.SUPER_ADMIN_BULK_NBFC} element={<Routers.SuperAdminAddBulkNbfc />} />
            <Route path={OurRoutes.SUPER_ADMIN_NBFC_INTERACTION} element={<Routers.SuperAdminNbfcInteraction />} />

            <Route path={OurRoutes.SUPER_ADMIN_RM_CONFIG} element={<Routers.SuperAdminRMConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_RM_DETAILS} element={<Routers.SuperAdminAddRM />} />
            <Route path={OurRoutes.SUPER_ADMIN_SCOUTS_PAGE} element={<Routers.SUPER_ADMIN_SCOUTS_PAGE />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_SCOUTS_PAGE} element={<Routers.SUPER_ADMIN_ADD_SCOUTS_PAGE />} />



            {/* opportunity start */}
            <Route path={OurRoutes.SUPER_ADMIN_OPPORTUNITIES} element={<Routers.SuperAdminAllOpportunityPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_OPPORTUNITIES_DETAILS} element={<Routers.SuperAdminOpportunityDetailPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_PERSONAL} element={<Routers.SuperAdminOpportunityEdit />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_BUSINESS} element={<Routers.SuperAdminOpportunityBusinessPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_SALARIED} element={<Routers.SuperAdminOpportunitysalaryPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_PROPERTY} element={<Routers.SuperAdminOpportunityPropertyPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_DIRECTOR} element={<Routers.SuperAdminOpportunityDirectorPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_ADDRESS} element={<Routers.SuperAdminOpportunityAddressPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_BANK} element={<Routers.SuperAdminOpportunityBankPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_LOAN} element={<Routers.SuperAdminOpportunityLoanPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_DOCUMENT} element={<Routers.SuperAdminOpportunityDocumentPage />} />

            <Route path={OurRoutes.SUPER_ADMIN_ADD_PAYLOAD} element={<Routers.SuperAdminAddPayload />} />
            <Route path={OurRoutes.SUPER_ADMIN_PAYLOAD_CONFIG} element={<Routers.SuperAdminPayloadConfig />} />
            {/* opportunity end */}
            {/* Chapters */}
            <Route path={OurRoutes.SUPER_ADMIN_ADD_CHAPTER_CONFIG} element={<Routers.AddChapterConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_CHAPTER_CONFIG} element={<Routers.ChapterConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_CHAPTER_CONFIG_DETAILS} element={<Routers.ChapterInfo />} />
            <Route path={OurRoutes.SUPER_ADMIN_RM_INFO} element={<Routers.RMiClick />} />


            <Route path={OurRoutes.SUPER_ADMIN_OPPORTUNITIES} element={<Routers.SuperAdminOpportunityConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_OPPORTUNITIES} element={<Routers.SuperAdminAddOpportunity />} />

            <Route path={OurRoutes.SUPER_ADMIN_BANK_CONFIG} element={<Routers.SuperAdminBankConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_BANK_CONFIG} element={<Routers.SuperAdminAddBankConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_BANK_INTERACTION} element={<Routers.SuperAdminBankInteraction />} />

            <Route path={OurRoutes.SUPER_ADMIN_BULK_ADD_BANK_CONFIG} element={<Routers.SuperAdminBulkAddBankConfig />} />
            <Route path={OurRoutes.THIRD_PARTY_KYC_ADMIN} element={<Routers.SuperAdminKyc />} />
            
            <Route path={OurRoutes.THIRD_PARTY_ADD_CRIF} element={<Routers.ThirdPartyAddCrif />} />
            <Route path={OurRoutes.THIRD_PARTY_CRIF_DETAILS} element={<Routers.ThirdPartyCrifConfig />} />

            <Route path={OurRoutes.KARZA_AADHAAR_INFO} element={<Routers.KarzaAadhaarInfo />} />
            <Route path={OurRoutes.KARZA_PAN_INFO} element={<Routers.KarzaPanInfo />} />

            <Route path={OurRoutes.GST_PORTAL_ADMIN} element={<Routers.SuperAdminGST />} />
            <Route path={OurRoutes.GST_ADMIN_INFO} element={<Routers.GSTAdminInfo />} />

            {/* Enterprise Data */}
            <Route path={OurRoutes.SUPER_ADMIN_ENTERPRISE_DATA_CONFIG} element={<Routers.SuperAdminEnterpriseConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ENTERPRISE_DATA_ADD} element={<Routers.SuperAdminEnterpriseConfigAdd />} />
            <Route path={OurRoutes.SUPER_ADMIN_ENTERPRISE_DATA_BULK_UPLOAD} element={<Routers.SuperAdminEnterpriseConfigBulkUpload />} />


            <Route path={OurRoutes.ADMIN_ADD_LEAD_PERSONAL} element={<Routers.SuperAdminLeadLeadEdit />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_BUSINESS} element={<Routers.CommunityAddLeadBusinessPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_SALARIED} element={<Routers.CommunityAddLeadsalaryPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_PROPERTY} element={<Routers.CommunityAddLeadPropertyPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_DIRECTOR} element={<Routers.CommunityAddLeadDirectorPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_ADDRESS} element={<Routers.CommunityAddLeadAddressPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_BANK} element={<Routers.CommunityAddLeadBankPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_LOAN} element={<Routers.CommunityAddLeadLoanPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_DOCUMENT} element={<Routers.CommunityAddLeadDocumentPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_NEWS} element={<Routers.SuperAdminAddNewsPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_NEWS} element={<Routers.SuperAdminNewsPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_BANK_CARDS} element={<Routers.SuperAdminBanks />} />
            <Route path={OurRoutes.SUPER_ADMIN_QR_LISTING} element={<Routers.SuperQrListing />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_QR} element={<Routers.SuperAdminAddQr />} />
            <Route path={OurRoutes.SUPER_ADMIN_QR_DETAILS} element={<Routers.SUPER_ADMIN_QR_DETAILS />} />
            <Route path={OurRoutes.ADMIN_BULK_COMMUNICATION_HISTORY} element={<Routers.ADMIN_BULK_COMMUNICATION_HISTORY />} />
            <Route path={OurRoutes.ADMIN_CALCULATOR} element={<Routers.AdminCalculator />} />
            <Route path={OurRoutes.ADMIN_QUERY} element={<Routers.CommunityQueries />} />
            <Route path={OurRoutes.SUPER_ADMIN_SCOUTS_DETAILS} element={<Routers.SUPER_ADMIN_SCOUTS_DETAILS />} ></Route>
            <Route path={OurRoutes.SUPER_ADMIN_MERCHANT_OPPORTUNITY} element={<Routers.SUPER_ADMIN_MERCHANT_OPPORTUNITY />} ></Route>
            <Route path={OurRoutes.SUPER_ADMIN_MERCHANT_OPPORTUNITY_LIST} element={<Routers.SUPER_ADMIN_MERCHANT_OPPORTUNITY_LIST />} ></Route>
            <Route path={OurRoutes.ADMIN_DISPUTE} element={<Routers.ADMIN_DISPUTE />} ></Route>
            <Route path={OurRoutes.OPERATIONS_REPORTS} element={<Routers.OPERATIONS_REPORTS />} ></Route>
            <Route path={OurRoutes.CREDIT_OPERATIONS_REPORTS} element={<Routers.CREDIT_OPERATIONS_REPORTS />} ></Route>
            <Route path={OurRoutes.OPERATIONS_REPORTS_LENDER} element={<Routers.OPERATIONS_REPORTS_LENDER />} ></Route>
            <Route path={OurRoutes.CREDIT_OPERATIONS_REPORTS_LENDER} element={<Routers.CREDIT_OPERATIONS_REPORTS_LENDER />} ></Route>
            <Route path={OurRoutes.COMMUNICATION_CONFIG} element={<Routers.CommunicationconfigNew />} ></Route>
            <Route path={OurRoutes.COMMUNICATION_LISTING} element={<Routers.COMMUNICATION_LISTING />} ></Route>
            <Route path={OurRoutes.COMMUNICATION_CONFIG_ALL} element={<Routers.COMMUNICATION_CONFIG_ALL />} ></Route>
            <Route path={OurRoutes.COMMUNICATION_CONFIG_DOCTOR} element={<Routers.COMMUNICATION_CONFIG_DOCTOR />} ></Route>
            <Route path={OurRoutes.DOCTOR_PRODUCT_CONFIG} element={<Routers.DOCTOR_PRODUCT_CONFIG />} ></Route>
            <Route path={OurRoutes.DOCTOR_PRODUCT_LIST} element={<Routers.DOCTOR_PRODUCT_LIST />} ></Route>
            <Route path={OurRoutes.DOCTOR_PRODUCT_CALCULATION} element={<Routers.DOCTOR_PRODUCT_CALCULATION />} ></Route>
            <Route path={OurRoutes.DOCTOR_RATING} element={<Routers.DOCTOR_RATING />} ></Route>
            <Route path={OurRoutes.MERCHANTS_REPORTS} element={<Routers.MERCHANTS_REPORTS />} ></Route>

            <Route element={<Routers.SUPER_ADMIN_PRODUCTIVITY />}>
              <Route path={OurRoutes.SUPER_ADMIN_ROADMAP} element={<Routers.SUPER_ADMIN_ROADMAP />} ></Route>

            </Route>
          </Route>

          {/* community pages */}
          <Route path={OurRoutes.COMMUNITY_LOGIN} element={<Routers.CommunityLoginPage />} />
          <Route element={<Routers.CommunityDashboardLayoutPage />}>
            <Route path={OurRoutes.COMMUNITY_DASHBOARD} element={<Routers.CommunityDashboardPage />} />
            <Route path={OurRoutes.COMMUNITY_ALL_LEADS} element={<Routers.CommunityAllLeadsPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_PERSONAL} element={<Routers.CommunityAddLeadPersonalPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_BUSINESS} element={<Routers.CommunityAddLeadBusinessPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_SALARIED} element={<Routers.CommunityAddLeadsalaryPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_PROPERTY} element={<Routers.CommunityAddLeadPropertyPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_DIRECTOR} element={<Routers.CommunityAddLeadDirectorPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_ADDRESS} element={<Routers.CommunityAddLeadAddressPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_BANK} element={<Routers.CommunityAddLeadBankPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_LOAN} element={<Routers.CommunityAddLeadLoanPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_DOCUMENT} element={<Routers.CommunityAddLeadDocumentPage />} />
            <Route path={OurRoutes.COMMUNITY_LEAD_DETAIL} element={<Routers.CommunityLeadDetailPage />} />
            <Route path={OurRoutes.COMMUNITY_BULK_COMMUNICATION} element={<Routers.BulkCommunicationPage />} />
            <Route path={OurRoutes.CRIF} element={<Routers.CrifPage />} />
            <Route path={OurRoutes.COMMUNITY_COMMUNITY_PAGE} element={<Routers.CommunityCommunityPage />} />
            <Route path={OurRoutes.COMMUNITY_COMMUNITY_DETAIL_PAGE} element={<Routers.CommunityCommunityDetailPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_PERSONAL} element={<Routers.CommunityAddCommunityPersonalPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_PROFESSIONAL} element={<Routers.CommunityAddCommunityProfessionalPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_ADDRESS} element={<Routers.CommunityAddCommunityAddressPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_BANK} element={<Routers.CommunityAddCommunityBankPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_DOCUMENT} element={<Routers.CommunityAddCommunityDocumentPage />} />
            <Route path={OurRoutes.COMMUNITY_BULK_COMMUNICATION_HISTORY} element={<Routers.BulkCommunicationHistoryPage />} />
            <Route path={OurRoutes.COMMUNITY_PROFILE_DETAILS} element={<Routers.CommunityProfileDetails />} />
            <Route path={OurRoutes.COMMUNITY_QUERY} element={<Routers.CommunityQueries />} />

            <Route path={OurRoutes.EDIT_PROFILE} element={<Routers.CommunityProfileDetailsEdit />} />
            <Route path={OurRoutes.COMMUNITY_LEDGER_LEADS} element={<Routers.CommunityLedgerLeads />} />
            <Route path={OurRoutes.COMMUNITY_GENERATE_REPORT} element={<Routers.CommunityGenerateReport />} />
            <Route path={OurRoutes.COMMUNITY_FORUMS} element={<Routers.CommunityForumsCreate />} />
            <Route path={OurRoutes.COMMUNITY_ALL_FORUMS} element={<Routers.CommunityAllForums />} />
            <Route path={OurRoutes.COMMUNITY_FORUMS_FOR_ALL} element={<Routers.CommunityForumForAll />} />
            <Route path={OurRoutes.FORUM_DETAILS_PAGE} element={<Routers.CommunityForumDetail />} />
            <Route path={OurRoutes.COMMUNITY_CALCULATOR} element={<Routers.CommunityCalculator />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITIES} element={<Routers.CommunityAllOpportunityPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITIES_DETAILS} element={<Routers.CommunityOpportunityDetailPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_PERSONAL} element={<Routers.CommunityOpportunityEdit />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_BUSINESS} element={<Routers.CommunityOpportunityBusinessPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_SALARIED} element={<Routers.CommunityOpportunitysalaryPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_PROPERTY} element={<Routers.CommunityOpportunityPropertyPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_DIRECTOR} element={<Routers.CommunityOpportunityDirectorPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_ADDRESS} element={<Routers.CommunityOpportunityAddressPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_BANK} element={<Routers.CommunityOpportunityBankPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_LOAN} element={<Routers.CommunityOpportunityLoanPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_DOCUMENT} element={<Routers.CommunityOpportunityDocumentPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_OPPORTUNITIES} element={<Routers.CommunityAddOpportunity />} />

          </Route>
          <Route path={OurRoutes.QR_DOWNLOAD} element={<Routers.QR_DOWNLOAD />} />
          <Route path={OurRoutes.ACTIVITY} element={<Routers.ACTIVITY />} />

          {/* lender pages */}
         

          {/* external community create */}
        
        </Routes>
      </Suspense>
    </Router>
    </Provider>
  )
}

export default AppRouter;
